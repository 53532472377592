// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageViewerViewport.empty ~ .ViewportOverlay {
  display: none;
}
.ViewportOverlay {
  color: #9ccef9;
}
.ViewportOverlay .overlay-element {
  position: absolute;
  font-weight: 400;
  text-shadow: 1px 1px #000;
  pointer-events: none;
}
.overlay-top {
  top: 28px;
}
.overlay-bottom {
  bottom: 3px;
}
.overlay-text {
  text-shadow: 0.8px 0.8px 0.5px rgba(0, 0, 0, 0.75);
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ViewportOverlay/ViewportOverlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,SAAS;AACX;AACA;EACE,WAAW;AACb;AAEA;EACE,kDAAkD;AACpD","sourcesContent":[".imageViewerViewport.empty ~ .ViewportOverlay {\n  display: none;\n}\n.ViewportOverlay {\n  color: #9ccef9;\n}\n.ViewportOverlay .overlay-element {\n  position: absolute;\n  font-weight: 400;\n  text-shadow: 1px 1px #000;\n  pointer-events: none;\n}\n.overlay-top {\n  top: 28px;\n}\n.overlay-bottom {\n  bottom: 3px;\n}\n\n.overlay-text {\n  text-shadow: 0.8px 0.8px 0.5px rgba(0, 0, 0, 0.75);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
