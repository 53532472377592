// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  background-color: #091731;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.infinite-loading-bar {
  -webkit-animation: side2side 2s ease-in-out infinite;
          animation: side2side 2s ease-in-out infinite;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  width: 50%;
}

@-webkit-keyframes side2side {
  0%,
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  50% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}

@keyframes side2side {
  0%,
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  50% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ProgressLoadingBar/ProgressLoadingBar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,oDAA4C;UAA5C,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;;IAEE,mCAA2B;YAA3B,2BAA2B;EAC7B;EACA;IACE,mCAA2B;YAA3B,2BAA2B;EAC7B;AACF;;AARA;EACE;;IAEE,mCAA2B;YAA3B,2BAA2B;EAC7B;EACA;IACE,mCAA2B;YAA3B,2BAA2B;EAC7B;AACF","sourcesContent":[".loading {\n  background-color: #091731;\n  height: 8px;\n  border-radius: 4px;\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n}\n\n.infinite-loading-bar {\n  animation: side2side 2s ease-in-out infinite;\n  height: 100%;\n  position: absolute;\n  border-radius: 4px;\n  width: 50%;\n}\n\n@keyframes side2side {\n  0%,\n  100% {\n    transform: translateX(-50%);\n  }\n  50% {\n    transform: translateX(150%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
