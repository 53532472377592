// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: var(--thumb-inner-color);
  border: 2px solid var(--thumb-outer-color);
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: var(--thumb-inner-color);
  border: 2px solid var(--thumb-outer-color);
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  border: none !important;
}

input[type='range']::-moz-range-track {
  border: none !important;
}

.range-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
}

input[type='range']::-moz-range-thumb {
  pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/InputRange/InputRange.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,0CAA0C;EAC1C,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,0CAA0C;EAC1C,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["input[type='range'] {\n  -webkit-appearance: none;\n}\n\ninput[type='range']::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  height: 13px;\n  width: 13px;\n  border-radius: 50%;\n  background-color: var(--thumb-inner-color);\n  border: 2px solid var(--thumb-outer-color);\n  cursor: pointer;\n}\n\ninput[type='range']::-moz-range-thumb {\n  height: 13px;\n  width: 13px;\n  border-radius: 50%;\n  background-color: var(--thumb-inner-color);\n  border: 2px solid var(--thumb-outer-color);\n  cursor: pointer;\n}\n\ninput[type='range']::-webkit-slider-runnable-track {\n  border: none !important;\n}\n\ninput[type='range']::-moz-range-track {\n  border: none !important;\n}\n\n.range-track {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\ninput[type='range']::-webkit-slider-thumb {\n  pointer-events: all;\n}\n\ninput[type='range']::-moz-range-thumb {\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
