// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-button-next.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

.swiper-button-prev.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

.swiper-button-prev {
  pointer-events: none;
  opacity: 0;
}

.swiper-button-next {
  pointer-events: none;
  opacity: 0;
}

.swiper-button-next-custom.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.swiper-button-prev-custom.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/LegacySidePanel/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AACA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AACA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".swiper-button-next.swiper-button-disabled {\n  pointer-events: none;\n  opacity: 0;\n}\n\n.swiper-button-prev.swiper-button-disabled {\n  pointer-events: none;\n  opacity: 0;\n}\n.swiper-button-prev {\n  pointer-events: none;\n  opacity: 0;\n}\n.swiper-button-next {\n  pointer-events: none;\n  opacity: 0;\n}\n\n.swiper-button-next-custom.swiper-button-disabled {\n  pointer-events: none;\n  opacity: 0.6;\n}\n\n.swiper-button-prev-custom.swiper-button-disabled {\n  pointer-events: none;\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
