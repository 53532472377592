// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO: Find a better way to set the cursor for all contents of dialog. */
.DraggableItem.draggable div {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}
.DraggableItem.draggable.dragging div {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/contextProviders/DialogProvider.css"],"names":[],"mappings":"AAAA,0EAA0E;AAC1E;EACE,+BAAuB;EAAvB,uBAAuB;AACzB;AAEA;EACE,mCAA2B;EAA3B,2BAA2B;AAC7B","sourcesContent":["/* TODO: Find a better way to set the cursor for all contents of dialog. */\n.DraggableItem.draggable div {\n  cursor: grab !important;\n}\n\n.DraggableItem.draggable.dragging div {\n  cursor: grabbing !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
