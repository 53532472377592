// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LineChart svg {
  color: #ddd;
  border: solid 1px #383f42;
}

.LineChart svg .background {
  stroke: none;
  fill: #151c20;
}

.LineChart svg .axis.label {
  fill: #ddd;
  font-size: 12px;
}

.LineChart svg .axis .tick line {
  stroke: #ddd;
}

.LineChart svg .axis .tick text {
  fill: #ddd;
}

.LineChart svg .axis.grid .domain {
  stroke: none;
}

.LineChart svg .axis.grid .tick line {
  stroke: #383f42;
}

.LineChart svg .line {
  stroke-width: 1px;
  fill: none;
}

.LineChart svg .legend .legend-background {
  fill: none;
  stroke: #383f42;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/LineChart/LineChart.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".LineChart svg {\n  color: #ddd;\n  border: solid 1px #383f42;\n}\n\n.LineChart svg .background {\n  stroke: none;\n  fill: #151c20;\n}\n\n.LineChart svg .axis.label {\n  fill: #ddd;\n  font-size: 12px;\n}\n\n.LineChart svg .axis .tick line {\n  stroke: #ddd;\n}\n\n.LineChart svg .axis .tick text {\n  fill: #ddd;\n}\n\n.LineChart svg .axis.grid .domain {\n  stroke: none;\n}\n\n.LineChart svg .axis.grid .tick line {\n  stroke: #383f42;\n}\n\n.LineChart svg .line {\n  stroke-width: 1px;\n  fill: none;\n}\n\n.LineChart svg .legend .legend-background {\n  fill: none;\n  stroke: #383f42;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
