// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type='text'] {
  -webkit-appearance: none;
}

/* For most modern browsers */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.input-number:focus {
  border: none;
  outline: none;
}

.up-arrowsize svg {
  width: 7px;
  height: 7px;
}

.up-arrowsize svg path {
  fill: #726f7e;
}

.input-small {
  height: 26px;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/InputNumber/InputNumber.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA,6BAA6B;;AAC7B;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,gBAAgB;;AAChB;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":["input[type='text'] {\n  -webkit-appearance: none;\n}\n\n/* For most modern browsers */\ninput[type='number']::-webkit-inner-spin-button,\ninput[type='number']::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* For Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n\n.input-number:focus {\n  border: none;\n  outline: none;\n}\n\n.up-arrowsize svg {\n  width: 7px;\n  height: 7px;\n}\n\n.up-arrowsize svg path {\n  fill: #726f7e;\n}\n\n.input-small {\n  height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
