// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cine-fps-range-tooltip .tooltip.tooltip-top {
  bottom: 85% !important;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/CinePlayer/CinePlayer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB","sourcesContent":[".cine-fps-range-tooltip .tooltip.tooltip-top {\n  bottom: 85% !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
