// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandableToolbarButton:hover .ExpandableToolbarButton__arrow:after {
  content: '';
  position: absolute;
  bottom: -10px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #5acce6 transparent;
}

.ExpandableToolbarButton .ExpandableToolbarButton__content {
  display: none;
}

.ExpandableToolbarButton:hover .ExpandableToolbarButton__content {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ExpandableToolbarButton/ExpandableToolbarButton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".ExpandableToolbarButton:hover .ExpandableToolbarButton__arrow:after {\n  content: '';\n  position: absolute;\n  bottom: -10px;\n  border-width: 10px 10px 0;\n  border-style: solid;\n  border-color: #5acce6 transparent;\n}\n\n.ExpandableToolbarButton .ExpandableToolbarButton__content {\n  display: none;\n}\n\n.ExpandableToolbarButton:hover .ExpandableToolbarButton__content {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
