// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-range-thumb-design {
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(9 12 41 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(90 204 230 / var(--tw-bg-opacity));
  border-radius: 50%
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/InputDoubleRange/InputDoubleRange.css"],"names":[],"mappings":"AACE;EAAA,iBAAqE;EAArE,mBAAqE;EAArE,sBAAqE;EAArE,qDAAqE;EAArE,kBAAqE;EAArE,wDAAqE;EACrE;AADqE","sourcesContent":[".input-range-thumb-design {\n  @apply bg-primary-light border-primary-dark border-[2px] border-solid;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
