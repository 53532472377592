// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  max-height: calc(100vh - 250px);
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC","sourcesContent":[".modal-content {\n  max-height: calc(100vh - 250px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
